<template>
  <div class="skimovieProblemsIssueSpots">
    <table
      v-if="issuesAvailable"
      class="defaultTable"
    >
      <colgroup>
        <col>
        <col width="100">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('installation') }}</th>
          <th>{{ $t('count') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(issue, index) in issues"
          :key="`SkimovieProblems-tr-${ index }`"
        >
          <td v-if="issue.installationId">
            <router-link :to="`/installation/${ issue.installationId }`">
              {{ issue.installationName }}
            </router-link>
          </td>
          <td v-else>
            {{ issue.name }}
          </td>
          <td>{{ issue.count }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SkimovieProblemsIssueSpots',
  props: {
    issues: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    issuesAvailable: function () {
      return this.issues && this.issues.length > 0;
    }
  }
}
</script>

<style scoped>
.skimovieProblemsIssueSpots {
  max-height: 300px;
  overflow-y: auto;
}
@media (min-width: 600px) {
  .skimovieProblemsIssueSpots {
    max-height: 600px;
  }
}
</style>
